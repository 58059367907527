import React from 'react';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ExpandedSection } from '../../Industry/SoftwareFeatures/ExpandedSection';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0px',
		borderRadius: '20px',
		background: 'transparent',
	},
	title: {
		fontSize: '1.875rem',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.625rem',
			textAlign: 'left',
		},
	},
	subheader: {
		fontSize: '1.625rem',
		fontWeight: 400,
		color: 'white',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.4rem',
			textAlign: 'left',
		},
	},
	sectionHeader: {
		fontWeight: 700,
		color: theme.workwaveBlue,
	},
	sectionSubHeader: {
		color: theme.workwaveBlue,
	},
}));

export const AbridgedFeatureList = ({
	softwareFeatures,
	header,
	subheader,
	modal,
	accentColor,
}) => {
	const classes = useStyles();
	const {
		features,
		expandText,
		expandedListHeader,
		expandedListSubheader,
		associatedProducts,
		comparisonHeader,
	} = softwareFeatures;
	return (
		<>
			{/* {!modal && (
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignContent='center'
					spacing={2}
					style={{
						textAlign: 'center',
						paddingTop: modal ? '0rem' : '0rem',
						marginBottom: '0rem',
					}}>
					<Grid item>
						<Typography variant='h2' className={classes.sectionHeader}>
							{header}
						</Typography>
					</Grid>

					<Grid item xs={12} md={8}>
						<Typography body='body1' className={classes.sectionSubHeader}>
							{subheader}
						</Typography>
					</Grid>
				</Grid>
			)} */}
			<Card className={classes.root} style={{ marginTop: modal && '0rem' }}>
				<CardHeader
					title={expandedListHeader}
					subheader={expandedListSubheader}
					classes={{ title: classes.title, subheader: classes.subheader }}
					style={{
						background: '#002D5C',
						padding: '20px',
						width: '100%',
						textAlign: 'center',
						color: 'white',
					}}
				/>
				<CardContent
					style={{
						padding: '35px',
						border: '2px solid #C3CAD9',
						borderBottomLeftRadius: '20px',
						borderBottomRightRadius: '20px',
						borderTop: 'none',
					}}>
					<ExpandedSection
						features={features}
						associatedProducts={associatedProducts}
					/>
					{/* <Grid
						style={{}}
						container
						direction='row'
						justifyContent='center'
						alignItems='center'>
						<a href='/pricing' style={{ textDecoration: 'none' }}>
							<Button
								variant='contained'
								size='large'
								style={{
									background: accentColor ?? '#002D5C',
									color: '#FFFFFF',
									margin: '2rem 0 0 0',
								}}>
								Learn more
							</Button>
						</a>
					</Grid> */}
				</CardContent>
			</Card>
		</>
	);
};
