import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, Popover, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		maxWidth: '400px',
		padding: '1rem',
		lineHeight: 1.3,
	},
}));

const Associated = ({ feature, products }) => {
	const productTitleOne = products?.cards[0]?.title;
	const productTitleTwo = products?.cards[1]?.title;
	const productTitleThree = products?.cards[2]?.title;

	// checks how many product cards get passed from sanity for the purpose of the title of product
	// and checks for the combination of true/false values for their respective feature. Turns it into
	// string literals of the product names
	const associatedProduct = (feature, products) => {
		if (products.cards.length === 1) {
			return productTitleOne;
		} else if (products.cards.length === 2) {
			if (feature.colOne && feature.colTwo) {
				return `${productTitleOne} & ${productTitleTwo}`;
			} else if (feature.colOne && !feature.colTwo) {
				return productTitleOne;
			} else if (!feature.colOne && feature.colTwo) {
				return productTitleTwo;
			} else {
				return null;
			}
		} else {
			if (feature.colOne && feature.colTwo && feature.colThree) {
				return `${productTitleOne} & ${productTitleTwo} & ${productTitleThree}`;
			} else if (feature.colOne && feature.colTwo && !feature.colThree) {
				return `${productTitleOne} & ${productTitleTwo}`;
			} else if (feature.colOne && !feature.colTwo && !feature.colThree) {
				return productTitleOne;
			} else if (!feature.colOne && feature.colTwo && feature.colThree) {
				return `${productTitleTwo} & ${productTitleThree}`;
			} else if (feature.colOne && !feature.colTwo && feature.colThree) {
				return `${productTitleOne} & ${productTitleThree}`;
			} else if (!feature.colOne && feature.colTwo && !feature.colThree) {
				return productTitleTwo;
			} else if (!feature.colOne && !feature.colTwo && feature.colThree) {
				return productTitleThree;
			} else {
				return null;
			}
		}
	};
	return (
		<Grid
			item
			container
			style={{ marginTop: '1rem' }}
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Typography variant='body2' color='primary'>
				Included with:
			</Typography>
			<Typography
				variant='body1'
				color='primary'
				style={{ fontWeight: 600, textAlign: 'center' }}>
				{associatedProduct(feature, products)}
			</Typography>
		</Grid>
	);
};

export const ExpandedSection = ({
	header,
	subheader,
	features,
	associatedProducts,
}) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperIndex, setPopperIndex] = useState(null);

	const handlePopoverOpen = (event, index) => {
		setPopperIndex(index);
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setPopperIndex(null);
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			{header && subheader && (
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignContent='center'
					spacing={2}
					style={{ textAlign: 'center', padding: '2rem 0' }}>
					<Grid item>
						<Typography
							variant='h4'
							color='primary'
							style={{ fontWeight: 700 }}>
							{header}
						</Typography>
					</Grid>

					<Grid item xs={12} md={8}>
						<Typography body='body1' color='primary'>
							{subheader}
						</Typography>
					</Grid>
				</Grid>
			)}
			<Grid container direction='row' spacing={2}>
				{features.map((feature, index) => (
					<>
						<Grid
							item
							md={4}
							key={index}
							container
							direction='row'
							alignItems='center'
							style={{
								flexWrap: 'unset',
							}}>
							<FontAwesomeIcon
								icon={['fas', 'check']}
								size='2x'
								style={{
									color: '#2A7ABC',
									marginRight: '.5rem',
									background: '#E5EAF4',
									borderRadius: '8px',
									padding: '2px 4px',
									cursor: 'pointer',
								}}
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup='true'
								onMouseEnter={(e) => handlePopoverOpen(e, index)}
								onMouseLeave={(e) => handlePopoverClose(e, index)}
							/>
							<Typography
								variant='h6'
								color='primary'
								style={{
									cursor: 'pointer',
									fontSize: '1.2rem',
									lineHeight: 1,
								}}
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup='true'
								onMouseEnter={(e) => handlePopoverOpen(e, index)}
								onMouseLeave={(e) => handlePopoverClose(e, index)}>
								{feature.featureTitle}
							</Typography>
						</Grid>
					</>
				))}

				<Popover
					id='mouse-over-popover'
					className={classes.popover}
					classes={{
						paper: classes.paper,
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus>
					{popperIndex !== null && (
						<>
							<Typography variant='body2' style={{ lineHeight: 1.3 }}>
								{features[popperIndex].featureDescription}
							</Typography>
							{/* <Associated
								products={associatedProducts}
								feature={features[popperIndex]}
							/> */}
						</>
					)}
				</Popover>
			</Grid>
		</>
	);
};
