import React from 'react';
import PortableText from 'react-portable-text';

import {
	Card,
	CardContent,
	Grid,
	Typography,
	useMediaQuery,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
		marginBottom: '8px',
	},
	ctaLink: {
		textDecoration: 'none',
		color: theme.white,
	},
	link: {
		'&:hover': {
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
}));

export const SupportedProductCard = ({ supportedProduct, index }) => {
	const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');
	return (
		<a href={supportedProduct.link} target='_blank' className={classes.ctaLink}>
			<Card style={{ padding: 0, cursor: 'pointer' }} elevation={4}>
				<Grid
					container
					direction='row'
					justifyContent='center'
					style={{ padding: '1rem 0' }}>
					<img
						src={
							supportedProduct.cardTopLogo.asset.gatsbyImageData?.images
								.fallback?.src
						}
						alt='supported'
						style={{ height: '4rem' }}
					/>
				</Grid>
				<CardContent
					style={{
						backgroundImage: `url(${supportedProduct.cardBottomImage?.asset?.gatsbyImageData?.images.fallback.src})`,
						backgroundSize: 'cover',
						borderRadius: 'none',
						height: med ? '20rem' : '25rem',
						backgroundPosition: 'bottom left',
					}}>
					<Grid
						style={{ height: '100%' }}
						container
						direction='row'
						alignItems='flex-end'
						justifyContent='flex-start'>
						<Grid item>
							{supportedProduct._rawCardText.map((content) => (
								<PortableText
									content={content}
									serializers={{
										normal: ({ children }) => (
											<Typography variant='h5' style={{ color: 'white' }}>
												{children}
											</Typography>
										),
										li: ({ children }) => (
											<Typography variant='h5' style={{ color: 'white' }}>
												<li>{children}</li>
											</Typography>
										),
										link: ({ children, href }) => (
											<Typography
												variant='h6'
												className={classes.link}
												style={{ marginTop: '2rem', fontWeight: 600 }}>
												{children}
												<span className={classes.arrow}>→</span>
											</Typography>
										),
									}}
								/>
							))}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</a>
	);
};
